.PlayerInfo {
  display: flex;
  padding: 15px;
}

.PlayerInfo__profile{
  display: flex;
  & span:nth-child(1){
    font-size: 13px;
    color: #888;
  }
}

.PlayerInfo__profile{
  display: flex;
}

.PlayerInfo__ico{
  margin-right: 10px;
}