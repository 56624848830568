.TicketInfo{
  flex-grow: 1;
}
.TicketInfo__card{
  display: flex;
  flex-direction: row;
}
.TicketInfo__about_ticket{
  display: flex;
  flex-direction: column;
  min-width: 240px;
  max-width: 350px;
}
.TicketInfo__line{
  margin: 5px 0 10px;
}
.TicketInfo__buttons{
  display: flex;
}
.TicketInfo__form{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
}
.TicketInfo__inputs{
  display: flex;
}
.TicketInfo__input{
  flex-grow: 1;
  margin-top: 10px !important;
  margin-left: 5px;
}

.CircularProgress{
  display: flex;
  justify-content: center;
  align-items: center;
}

