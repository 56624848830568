.Message{
  display: flex;
  padding: 15px;
  background-color: #dff0d8;
  margin-top: 1px
}
.Message__info{
  display: flex;
  flex-direction: column;
  width: 235px;
  font-size: 10px;
}
.Message__info_title{
  color: #777;
}
.Message__text{
  font-size: 12px;
  display: flex;
  flex-direction: column;
}
