.TicketsList{
  margin-left: 0;
  padding-left: 0;
  max-width: 350px;
  & li{
    list-style-type: none;
    margin-left: 15px;
    margin-bottom: 2px;
  }
}

.TicketsList__card{
display: flex;
flex-direction: column;
padding: 15px;
background-color: #fcf8e3 !important;
color: #8a6d3b !important;
  &:hover{
    background-color: rgba(0, 0, 0, 0.19) !important;
    cursor: pointer;
  }
}
.close {
  background-color: rgba(20, 145, 44, 0.78) !important;
}

.waiting_for_player {
  background-color: rgba(233, 238, 249, 0.78) !important;
}

.waiting {
  background-color: rgba(24, 196, 249, 0.65) !important;
}

.TicketsList__card_active{
  background-color: rgba(51, 122, 183, 0.53) !important;
  color: #fff !important;
  &:hover{
    background-color: rgba(76, 74, 81, 0.33) !important;
    cursor: pointer;
  }
}
