body {
  font-family: "Roboto";
  font-weight: 200; }

.content {
  margin-top: 1rem; }

@media (min-width: 1200px) {
  .container {
    max-width: 1400px !important; } }

.bgImageMain {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  opacity: 0.2; }

.projectsContainer {
  display: flex;
  flex-wrap: wrap;
  position: relative; }
  .projectsContainer .projectItem {
    width: 30%;
    margin: 30px 1.5%;
    padding: 9% 0;
    position: relative; }
    .projectsContainer .projectItem .projectName {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 45%;
      left: 50%;
      font-size: 24px;
      text-align: center; }
    .projectsContainer .projectItem .projectImage {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: cover; }

.helperDrag {
  background-color: #fff;
  box-shadow: 0 24px 45px 0 rgba(0, 0, 0, 0.2), 0 -24px 45px 0 rgba(0, 0, 0, 0.2);
  z-index: 1400; }

.dropdown .dropdown-menu {
  padding: .5rem; }

.dropdown .dropdown-menu .dropdown-item {
  outline: none;
  padding: .5rem;
  margin-left: 0;
  font-size: .9rem;
  transition: all .1s linear; }

.dropdown .dropdown-menu .dropdown-item:hover {
  background: #e5e6e7;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: .125rem; }

.dropdown .dropdown-menu .dropdown-item:active {
  background: #b7b8b8; }

.btn-rounded {
  border-radius: 10em; }

.btn-floating {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1;
  vertical-align: middle;
  overflow: hidden;
  transition: all .2s ease-in-out;
  margin: 10px;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  width: 47px;
  height: 47px;
  outline: none; }

.btn-floating:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.btn-floating.btn-sm {
  width: 36.15385px;
  height: 36.15385px; }

.custom-table thead:last-child {
  display: none; }

.loading-container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  align-items: center;
  justify-content: center; }

.config-table td {
  vertical-align: middle;
  line-height: 2; }

.btn-smaller {
  padding: 0.3rem 0.8rem !important;
  font-size: 0.64rem !important;
  font-weight: 400 !important;
  border-radius: 0.125rem !important; }

.btn-smallest {
  font-size: 0.64rem !important;
  margin: 0.25rem 0.1rem !important;
  padding: 0.16rem 0.46rem !important;
  font-weight: 400 !important;
  color: #fff !important;
  border-radius: 0.125rem !important; }

.dataTables_length {
  display: none; }

.dataTables_filter label {
  color: transparent; }

.card-hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border: 0;
  text-align: center;
  cursor: pointer;
  padding: 0.2rem 0.4rem; }

.card-hover:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15); }

.dataTables_filter input[type=search] {
  box-sizing: content-box;
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #ced4da;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  outline: 0;
  margin-left: .5rem;
  display: inline-block;
  width: auto;
  padding: .3rem 0 .55rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  margin-left: -3rem; }

.dataTables_filter input[type=search]:focus {
  box-shadow: 0 1px 0 0 #4285f4;
  border-bottom: 1px solid #4285f4; }

.dataTables_wrapper > .row:first-child > div:first-child {
  display: none; }

.dataTables_wrapper .row:first-child div {
  text-align: left; }

.dataTables_paginate li {
  display: none; }

.dataTables_paginate li:nth-child(1),
.dataTables_paginate li:nth-child(2),
.dataTables_paginate li:nth-last-child(1),
.dataTables_paginate li:nth-last-child(2),
.dataTables_paginate li.active,
.dataTables_paginate li.active + li,
.dataTables_paginate li.active + li + li {
  display: block; }

.dataTables_wrapper > .row:last-child > div:first-child {
  display: none; }

.uploaded-list-item {
  padding: .5rem;
  margin-top: .5rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto; } }

.upload-files-list {
  overflow-y: scroll;
  max-height: 400px;
  padding: .5rem; }

.upload-files-list > div {
  cursor: default; }

.upload-files-list > div:hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15); }

pre.json {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  border: 1px solid #ccc; }

.table-wrapper {
  overflow-x: scroll;
  overflow-y: visible;
  margin-left: 200px; }

.table-wrapper td,
.table-wrapper th {
  min-width: 250px; }

.table-wrapper th {
  border-bottom: none !important; }

.table-wrapper td:first-child,
.table-wrapper th:first-child {
  position: absolute;
  left: 20px;
  min-width: 200px !important; }

.cross-icon {
  border-radius: 50%;
  padding: 3px;
  vertical-align: middle;
  line-height: 100%;
  font-size: 14px;
  height: 20px;
  width: 20px;
  text-align: center; }

.cross-icon:hover {
  background: #ededed; }

.to-debug-badge {
  background-color: #4285f4 !important; }

.to-stage-badge,
.to-stage1-badge {
  background-color: #ff8800 !important; }

.to-published-badge,
.to-release-badge {
  background-color: #ff3547 !important; }

.editable-cell {
  outline: none;
  border: none;
  padding: 0;
  width: 100%; }

.hoverable-text {
  cursor: pointer;
  text-decoration: underline; }

.table-centered {
  text-align: center; }

.table-centered td {
  vertical-align: middle; }

.pvp-table-win {
  background: rgba(0, 200, 81, 0.2); }

.pvp-table-lose {
  background: rgba(255, 53, 71, 0.2); }

.value-editable {
  cursor: pointer; }

.value-editable .value-editable-icon {
  visibility: hidden; }

.value-editable:hover .value-editable-icon {
  visibility: visible; }

.skeleton-block {
  background-image: linear-gradient(100deg, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), linear-gradient(lightgray 100%, transparent 0);
  background-size: 100px, 100%, 100% 100%;
  background-position: 0 0, 0 0;
  background-repeat: no-repeat;
  animation: shine 1.5s infinite; }

@keyframes shine {
  to {
    background-position: 100% 0, 0 0; } }

.header-skeleton {
  height: 4rem; }

.column-row {
  padding-bottom: 2.5rem; }
  .column-row .rule-row {
    padding: 30px 24px 50px; }
    .column-row .rule-row:last-child {
      margin-bottom: none; }
  .column-row .add-rule-row {
    margin-top: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .column-row:last-child {
    border-bottom: none; }

.rules-col-padding {
  padding-left: 12px;
  padding-right: 12px; }

.rules-header-title {
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.0075em; }

.headerProject {
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  padding: 0 10px;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  border-radius: 0.125rem;
  margin-right: 0.8rem; }

.customSelect_rootSelect {
  background-color: #4c535f !important;
  border-radius: 0.125rem !important;
  border: 0 !important;
  color: #fff !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important; }

.diff_rootSelect {
  background-color: #78909c !important; }

.customSelect_select {
  padding: 0.3rem 0 !important;
  font-size: 0.64rem !important;
  padding-right: 20px !important;
  padding-left: 12px !important;
  line-height: 1.5 !important;
  text-transform: uppercase;
  font-weight: 400 !important; }

.customSelect_icon {
  color: #fff !important;
  font-size: 18px !important;
  top: 50% !important;
  transform: translateY(-55%) !important; }

.customSelect_rootItem {
  border-radius: 5px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important; }
  .customSelect_rootItem:hover {
    background-color: #4c535f !important;
    color: white !important; }

.globalDiffWindow {
  position: fixed;
  top: 82px;
  left: 10px;
  z-index: 2;
  display: flex;
  align-items: center;
  transition: transform 0.1s linear; }

.globalDiffContent {
  padding: 10px 20px;
  padding-bottom: 20px;
  background-color: #78909c;
  border-radius: 5px;
  width: 170px; }
  .globalDiffContentBody {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .globalDiffContentBody > div {
      margin: 5px 0; }
    .globalDiffContentBody h6 {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 15px;
      color: #fff; }
    .globalDiffContentBody .customSelect_rootSelect {
      width: 120px;
      color: #212529;
      background-color: #fff; }
    .globalDiffContentBody .customSelect_icon {
      color: #212529 !important; }

.globalDiffBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 5px;
  background-color: #78909c;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.localizMoreStateBtn {
  margin-left: 10px !important;
  background-color: #464967 !important; }

.configTr_title {
  display: flex;
  align-items: center;
  cursor: pointer; }

.configTr_titleIcon {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px; }
  .configTr_titleIcon svg {
    width: 20px;
    height: 20px; }

.publishModalCheckboxLabel {
  margin: 0 !important; }

.In-game-tickets .MuiOutlinedInput-input {
  padding: 2px; }

.table-row:hover {
  background-color: rgba(77, 77, 79, 0.31);
  cursor: pointer; }

.ticket-modal {
  display: flex;
  padding: 15px; }
  .ticket-modal__ico {
    margin-right: 10px; }
  .ticket-modal__profile {
    display: flex; }
